import { LandingBlogItem } from '@b2w/shared/types';
import ContentLoader from 'react-content-loader';
import AppLink from '../AppLink';

export const LandingBlogItemsRendererSkeleton = () => {
  return (
    <div
      className="d-flex flex-wrap"
      style={{ justifyContent: 'space-evenly' }}
    >
      {Array(3)
        .fill(null)
        .map((_item, idx) => (
          <div
            className="m-2 shadow-sm"
            key={idx}
            style={{ maxWidth: '100%', width: 250, height: 250 }}
          >
            <ContentLoader
              uniqueKey={`itm-${idx}-loader`}
              speed={2}
              viewBox="0 0 250 250"
            >
              <rect x="0" y="0" rx="4" ry="4" width="250" height="250" />
            </ContentLoader>
          </div>
        ))}
    </div>
  );
};

type LandingBlogItemsRendererProps = {
  items: LandingBlogItem[];
};

const LandingBlogItemsRenderer = ({ items }: LandingBlogItemsRendererProps) => {
  const renderItem = (item: LandingBlogItem) => {
    return (
      <div style={{ maxWidth: '100%', width: 250, height: 250 }}>
        <img
          src={item.image}
          alt=""
          className="rounded w-100 h-100"
          style={{ objectFit: 'cover' }}
        />
      </div>
    );
  };

  return (
    <div
      className="d-flex flex-wrap"
      style={{ justifyContent: 'space-evenly' }}
    >
      {items.map((item) => (
        <div className="m-2 shadow-sm" key={item.href + item.image}>
          {item.href ? (
            <AppLink href={item.href}>{renderItem(item)}</AppLink>
          ) : (
            renderItem(item)
          )}
        </div>
      ))}
    </div>
  );
};

export default LandingBlogItemsRenderer;
