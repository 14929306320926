import AppLink from '@/components/AppLink';
import { PopularDestinationModel } from '@b2w/shared/types';

type Destination = {
  name: string;
  iconSrc: string;
  href: string;
};

const DestinationItem = ({
  iconSrc,
  href = '/search',
  hrefText,
  className = ''
}) => {
  return (
    <AppLink
      href={href}
      prefetch={false}
      className={`d-flex align-items-center ${className}`}
    >
      <img
        src={iconSrc}
        alt={hrefText}
        width={50}
        height={50}
        className="mr-2"
      />
      <span className="text-primary text-nowrap">{hrefText}</span>
    </AppLink>
  );
};

const destinationIcons = [
  '/images/town.png',
  '/images/sunpalms.png',
  '/images/animal.png',
  '/images/shark.png',
  '/images/beach.png',
  '/images/lounge.png'
];
const defaultDestinations = [
  {
    name: 'Manila',
    iconSrc: destinationIcons[0],
    href: '/search'
  },
  {
    name: 'Ormoc',
    iconSrc: destinationIcons[1],
    href: '/search'
  },
  {
    name: 'Bohol',
    iconSrc: destinationIcons[2],
    href: '/search'
  },
  {
    name: 'Cebu',
    iconSrc: destinationIcons[3],
    href: '/search'
  },
  {
    name: 'Cagayan De Oro',
    iconSrc: destinationIcons[4],
    href: '/search'
  },
  {
    name: 'Camotes Island',
    iconSrc: destinationIcons[5],
    href: '/search'
  }
];

const DestinationsBlock = ({
  destinations
}: {
  destinations: Destination[];
}) => {
  const gridCols = 3;
  const gridRows = Math.ceil(destinations.length / gridCols);

  return (
    <>
      {Array(gridCols)
        .fill(null)
        .map((_, idx) => {
          const idxToSlice = idx > 0 ? idx * gridRows : 0;

          return (
            <div key={idx} className="d-flex flex-column mr-5">
              {destinations
                .slice(idxToSlice, idxToSlice + gridRows)
                .map((d) => (
                  <DestinationItem
                    key={d.name}
                    className="my-1"
                    iconSrc={d.iconSrc}
                    hrefText={d.name}
                    href={d.href}
                  />
                ))}
            </div>
          );
        })}
    </>
  );
};

const MainDestination = ({ title, text }) => {
  return (
    <>
      <h3 className="h4 text-primary">{title}</h3>
      <p className="text-secondary-dark">{text}</p>
    </>
  );
};

type Props = {
  data: PopularDestinationModel;
};

const PopularDestination = ({ data }: Props) => {
  const destinations: Destination[] =
    data.destinations?.length > 0
      ? data.destinations.map((c, idx) => ({
          href: c.href,
          iconSrc: c.imgSrc || destinationIcons[idx % destinationIcons.length],
          name: c.name
        }))
      : defaultDestinations;

  return (
    <div>
      <div className="d-flex flex-column flex-lg-row align-items-lg-center mb-4">
        <div className="pr-5">
          <MainDestination
            title={data.descriptionBlock.title}
            text={data.descriptionBlock.text}
          />
        </div>
        <div className="flex-fill">
          <div className="d-flex flex-column flex-md-row justify-content-center">
            <DestinationsBlock destinations={destinations} />
          </div>
        </div>
      </div>
      <img
        src={data.coverImg}
        alt={data.descriptionBlock.title}
        className="w-100 h-auto"
      />
    </div>
  );
};

export default PopularDestination;
