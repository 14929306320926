import { useLandingBlog } from '@/lib/useLandingBlog';
import LandingBlogItemsRenderer, {
  LandingBlogItemsRendererSkeleton
} from './LandingBlogItemsRenderer';

const LandingBlog = () => {
  const { landingBlog, error } = useLandingBlog();

  if (landingBlog === null || error) {
    return null;
  }

  if (landingBlog?.items) {
    return <LandingBlogItemsRenderer items={landingBlog.items} />;
  }

  return <LandingBlogItemsRendererSkeleton />;
};

export default LandingBlog;
