import { twCx } from '@b2w/react-ui/core2';

const partnersList = [
  {
    src: '/images/partner_danishfoundationlogo.png',
    alt: 'Danish Foundation'
  },
  {
    src: '/images/partner_cselogo.jpg',
    alt: 'Copenhagen School of Entrepreneurship'
  }
];

const PartnersBlock = () => {
  return (
    <div className="d-flex flex-column flex-lg-row align-items-lg-center">
      <style jsx>
        {`
          .justify-content-evenly {
            justify-content: space-evenly;
          }
        `}
      </style>
      <div className="text-secondary-dark h5 m-0 font-weight-normal mx-lg-5 mb-4 mb-lg-0">
        We are connected to
      </div>
      <style jsx>
        {`
          .partner {
            height: 50px;
            width: auto;
          }
        `}
      </style>
      <div className="d-flex flex-column flex-sm-row align-items-center justify-content-evenly flex-fill">
        {partnersList.map((p, idx, arr) => (
          <img
            key={p.src}
            src={p.src}
            alt={p.alt}
            height="50px"
            width="150px"
            className={twCx('partner mh-100 mw-100', {
              'mb-4 mb-sm-0': idx + 1 < arr.length
            })}
          />
        ))}
      </div>
    </div>
  );
};

export default PartnersBlock;
