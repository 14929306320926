import { twCx } from '@b2w/react-ui/core2';

const awards = [
  {
    thumb: '/images/award_cover_danish_2016.png',
    thumbAlt: 'The winner of the Danish Entrepreneurship Award 2016',
    title: 'The winner of the Danish Entrepreneurship Award 2016',
    description:
      'Book2wheel won the 2016 Danish entrepreneurship award among 7000 participants in Fredericia Denmark.',
    link: '/press'
  },
  {
    thumb: '/images/award_cover_startup_2018.jpg',
    thumbAlt: 'Best startup of the year 2018',
    title: 'Best startup of the year 2018',
    description:
      'Book2wheel won the 2018 best start up of the year in UCL incubator competition. Book2wheel also one the first prize for best forecast category.',
    link: '/press'
  }
];

const AwardsBlock = () => {
  return (
    <div className="d-flex flex-column">
      <style jsx>
        {`
          .award-card {
            border-radius: 10px;
          }

          .award-img-wrapper {
            width: 330px;
            height: 200px;
          }

          .award-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0 10px 10px 0;
          }

          @media screen and (max-width: 992px) {
            .award-img-wrapper {
              width: 250px;
              height: 100%;
            }
          }

          @media screen and (max-width: 768px) {
            .award-img-wrapper {
              width: 175px;
            }
          }

          @media screen and (max-width: 576px) {
            .award-img-wrapper {
              width: 100%;
              height: 150px;
            }
            .award-img {
              border-radius: 10px 10px 0 0;
            }
          }
        `}
      </style>
      {awards.map((a, idx, arr) => (
        <div
          key={a.title}
          className={twCx(
            'award-card d-flex flex-column-reverse flex-sm-row shadow mx-3 mx-lg-5',
            {
              'mb-4': idx + 1 < arr.length
            }
          )}
        >
          <div className="p-3 p-sm-4 mr-lg-5 align-self-center">
            <h4 className="h5 text-primary">{a.title}</h4>
            <p className="text-secondary-dark tw-mb-4">{a.description}</p>
            <a
              href={a.link}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              Read more about it.
            </a>
          </div>
          <div>
            <div className="award-img-wrapper">
              <img
                src={a.thumb}
                alt={a.thumbAlt}
                className="award-img"
                width="200"
                height="200"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AwardsBlock;
