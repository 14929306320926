export interface BlogPost {
  id: number;
  link: string;
  title: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
    protected: boolean;
  };
  jetpack_featured_media_url: string;
}

class BlogService {
  private static _instance: BlogService;

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  getBlogURL() {
    return 'https://www.blog.book2wheel.com/';
  }

  async getPosts(): Promise<BlogPost[]> {
    return this.fetchFlog(
      '/posts?orderby=date&order=desc&page=1&per_page=3&_fields=title,link,id,excerpt,jetpack_featured_media_url'
    );
  }

  private async fetchFlog(endpoint: string) {
    return fetch(
      'https://www.blog.book2wheel.com/wp-json/wp/v2' + endpoint
    ).then((r) => r.json());
  }
}

export const blogService = BlogService.Instance;
