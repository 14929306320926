import React from 'react';
import { Container } from 'reactstrap';

type Props = React.PropsWithChildren<{
  title?: string;
  titleExtraClassName?: string;
}>;

const HomeSection = ({ title, children, titleExtraClassName = '' }: Props) => {
  return (
    <div className="py-5">
      <Container>
        {title && (
          <div className="text-center">
            <h2
              className={`d-inline-block h3 mb-5 text-primary font-weight-bold ${titleExtraClassName}`}
            >
              {title}
            </h2>
          </div>
        )}
        {children}
      </Container>
    </div>
  );
};

export default HomeSection;
