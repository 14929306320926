import { B2wApiError, LandingBlogModel } from '@b2w/shared/types';
import useSWR, { useSWRConfig } from 'swr';
import { projectService } from './services/project.service';

const CACHE_KEY = 'getLandingBlog';

export const useInvalidateLandingBlog = () => {
  const { cache } = useSWRConfig();

  return () => cache.delete(CACHE_KEY);
};

export const useLandingBlog = (mustFetch = true) => {
  const { cache } = useSWRConfig();
  const { data: landingBlog, error } = useSWR<LandingBlogModel, B2wApiError>(
    mustFetch ? CACHE_KEY : null,
    () => projectService.getLandingBlog(),
    {
      revalidateOnMount: !cache.get(CACHE_KEY)?.data
    }
  );
  const isLandingBlogLoading = !landingBlog && !error;

  return {
    landingBlog,
    error,
    isLandingBlogLoading
  };
};
