import { MAIN_TRAVEL_DIRECTIONS } from '@/lib/directions-links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppLink from '@/components/AppLink';
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown
} from 'reactstrap';
import css from 'styled-jsx/css';
import { twCx } from '@b2w/react-ui/core2';

function getDestinationDropdownItemStyles() {
  return css.resolve`
    a {
      text-decoration: none;
    }
    a:hover {
      background-color: var(--secondary-light);
    }
  `;
}

type DestinationInputProps = {
  cities: { name: string; searchPageHref: string }[];
  title: string;
  flag: string;
};

const DestinationInput = ({ title, flag, cities }: DestinationInputProps) => {
  const {
    className: dropdownItemClassName,
    styles: dropdownItemClassNameStyles
  } = getDestinationDropdownItemStyles();

  return (
    <UncontrolledButtonDropdown className="w-100">
      <DropdownToggle
        color="white"
        style={{
          borderRadius: 30,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
          padding: '7px 17px',
          fontSize: '1.1rem',
          width: 200,
          minWidth: 150
        }}
        className="font-weight-500 text-secondary-dark d-flex flex-row align-items-center justify-content-between"
      >
        <div className="d-flex flex-row align-self-md-center">
          <style jsx>
            {`
              .flag-img-wrap {
                width: 25px;
                height: 25px;
              }

              .flag-img-wrap > img {
                object-fit: cover;
                transform: scale(1.5);
              }
            `}
          </style>
          <div className="flag-img-wrap overflow-hidden rounded-circle d-flex justify-content-center align-items-center border shadow-sm mr-2">
            <img
              src={flag}
              alt={title}
              width="25px"
              height="25px"
              className="w-100 h-100"
            />
          </div>
          <span>{title}</span>
        </div>
        <FontAwesomeIcon icon="chevron-down" />
      </DropdownToggle>
      <DropdownMenu className="w-100 mt-1">
        {cities.map((o) => (
          <div key={o.name}>
            {dropdownItemClassNameStyles}
            <AppLink
              href={o.searchPageHref}
              className={twCx(
                dropdownItemClassName,
                'd-block px-3 py-2 font-weight-bold d-flex justify-content-between align-items-center text-dark'
              )}
            >
              {o.name}{' '}
              <FontAwesomeIcon icon="chevron-right" className="text-dark" />
            </AppLink>
          </div>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
};

const DestinationsBlock = () => {
  return (
    <div>
      <div className="d-flex flex-column flex-md-row justify-content-around mb-md-5">
        <div className="mb-4 mb-md-0">
          <DestinationInput
            flag={MAIN_TRAVEL_DIRECTIONS.ph.flagHref}
            title={MAIN_TRAVEL_DIRECTIONS.ph.name}
            cities={MAIN_TRAVEL_DIRECTIONS.ph.cities}
          />
        </div>
        <div className="mb-4 mb-md-0">
          <DestinationInput
            flag={MAIN_TRAVEL_DIRECTIONS.ind.flagHref}
            title={MAIN_TRAVEL_DIRECTIONS.ind.name}
            cities={MAIN_TRAVEL_DIRECTIONS.ind.cities}
          />
        </div>
        <div className="mb-4 mb-md-0">
          <DestinationInput
            flag={MAIN_TRAVEL_DIRECTIONS.pk.flagHref}
            title={MAIN_TRAVEL_DIRECTIONS.pk.name}
            cities={MAIN_TRAVEL_DIRECTIONS.pk.cities}
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-around mb-md-5">
        <div className="mb-4 mb-md-0 ">
          <DestinationInput
            flag={MAIN_TRAVEL_DIRECTIONS.co.flagHref}
            title={MAIN_TRAVEL_DIRECTIONS.co.name}
            cities={MAIN_TRAVEL_DIRECTIONS.co.cities}
          />
        </div>
        <div className="mb-4 mb-md-0 ">
          <DestinationInput
            flag={MAIN_TRAVEL_DIRECTIONS.ec.flagHref}
            title={MAIN_TRAVEL_DIRECTIONS.ec.name}
            cities={MAIN_TRAVEL_DIRECTIONS.ec.cities}
          />
        </div>
        <div className="mb-4 mb-md-0 ">
          <DestinationInput
            flag={MAIN_TRAVEL_DIRECTIONS.ch.flagHref}
            title={MAIN_TRAVEL_DIRECTIONS.ch.name}
            cities={MAIN_TRAVEL_DIRECTIONS.ch.cities}
          />
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row justify-content-around">
        <div className="mb-4 mb-md-0 ">
          <DestinationInput
            flag={MAIN_TRAVEL_DIRECTIONS.vnd.flagHref}
            title={MAIN_TRAVEL_DIRECTIONS.vnd.name}
            cities={MAIN_TRAVEL_DIRECTIONS.vnd.cities}
          />
        </div>
        <div>
          <DestinationInput
            flag={MAIN_TRAVEL_DIRECTIONS.dk.flagHref}
            title={MAIN_TRAVEL_DIRECTIONS.dk.name}
            cities={MAIN_TRAVEL_DIRECTIONS.dk.cities}
          />
        </div>
      </div>
    </div>
  );
};

export default DestinationsBlock;
