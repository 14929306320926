import {
  RemoteConfigDTO,
  ProjectStatsModel,
  ProjectAppConfigModel,
  UpdatePromoBannerBodyDTO,
  LandingBlogModel,
  UpdateLandingBlogBodyDTO,
  UpdateEmailMarketingBodyDTO,
  PopularDestinationModel,
  UpdatePopularDestinationBodyDTO,
  ProjectChartStatsDTO
} from '@b2w/shared/types';
import { MainApiService } from './main-api.service';

// https://stackoverflow.com/a/36978360
class ProjectService extends MainApiService {
  private prefix = '/project';
  private static _instance: ProjectService;

  private constructor() {
    super();
  }

  public static get Instance() {
    return this._instance || (this._instance = new this());
  }

  async getProjectStats(): Promise<ProjectStatsModel | null> {
    const endpoint = this.prefix + '/stats';
    return this.get<ProjectStatsModel | null>(endpoint);
  }

  async getChartStats(): Promise<ProjectChartStatsDTO> {
    const endpoint = this.prefix + '/chart-stats';
    return this.get<ProjectChartStatsDTO>(endpoint);
  }

  async getRemoteConfig(): Promise<RemoteConfigDTO> {
    const endpoint = this.prefix + '/remote-config';
    return this.get<RemoteConfigDTO>(endpoint);
  }

  async getProjectAppConfig(): Promise<ProjectAppConfigModel> {
    const endpoint = this.prefix + '/app-config';
    return this.get<ProjectAppConfigModel>(endpoint);
  }

  async updateActiveBanner(body: UpdatePromoBannerBodyDTO): Promise<void> {
    const endpoint = this.prefix + '/active-banner';
    return this.post<void>(endpoint, body, { withAuth: true });
  }

  async updateEmailMarketingSettings(
    body: UpdateEmailMarketingBodyDTO
  ): Promise<void> {
    const endpoint = this.prefix + '/email-marketing';
    return this.post<void>(endpoint, body, { withAuth: true });
  }

  async getLandingBlog(): Promise<LandingBlogModel | null> {
    const endpoint = this.prefix + '/landing-blog';
    return this.get<LandingBlogModel | null>(endpoint, { withAuth: false });
  }

  async updateLandingBlogItems(
    payload: UpdateLandingBlogBodyDTO
  ): Promise<void> {
    const endpoint = this.prefix + '/landing-blog';
    return this.post<void>(endpoint, payload, { withAuth: true });
  }

  async getPopularDestination(): Promise<PopularDestinationModel | null> {
    const endpoint = this.prefix + '/popular-destination';
    return this.get<PopularDestinationModel | null>(endpoint, {
      withAuth: false
    });
  }

  async updatePopularDestination(
    payload: UpdatePopularDestinationBodyDTO
  ): Promise<void> {
    const endpoint = this.prefix + '/popular-destination';
    return this.post<void>(endpoint, payload, { withAuth: true });
  }
}

export const projectService = ProjectService.Instance;
