import { Button } from 'reactstrap';
import ContentLoader from 'react-content-loader';
import useSWR from 'swr';
import dynamic from 'next/dynamic';
import { BlogPost, blogService } from '@/lib/services/blog.service';

const BlogItemLoader = () => {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 750 145"
      preserveAspectRatio="none"
      uniqueKey="blog-item-loader"
      style={{ minHeight: 250, maxWidth: '100%' }}
      className="blog-loader"
    >
      <rect x="0" y="0" rx="3" ry="3" width="750" height="145" />
    </ContentLoader>
  );
};

const BlogCarousel = dynamic(() => import('./BlogCarousel'), {
  ssr: false,
  loading: () => <BlogItemLoader />
});

function stripHtmlAndGetFirstSentence(html: string) {
  const textNoHtml = html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');

  const regex = /.*?(\.)(?=\s[A-Z])/;

  const sentenceMatch = regex.exec(textNoHtml);

  if (sentenceMatch) {
    return sentenceMatch[0];
  }

  return textNoHtml.split('.')[0];
}

const BlogItem = ({ imgSrc, title, text, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="blog-item d-flex flex-column flex-md-row shadow link-unstyled position-relative"
    >
      <style jsx>
        {`
          .blog-item {
            width: 520px;
            height: 200px;
            border-radius: 20px;
          }

          .blog-img-wrapper {
            width: 245px;
            height: 100%;
          }

          .blog-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px 0 0 20px;
          }

          @media screen and (max-width: 768px) {
            .blog-item {
              margin: auto;
              min-height: 260px;
              max-height: 350px;
              width: 90%;
            }

            .blog-img-wrapper {
              width: 100%;
              height: 150px;
            }
            .blog-img {
              border-radius: 10px 10px 0 0;
            }
          }

          @media screen and (min-width: 768px) {
            .blog-item:hover {
            }
            .bottom-fade {
              position: relative;
            }
            .bottom-fade:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              height: 35px;
              width: 100%;
              background: linear-gradient(transparent, white);
            }
          }
        `}
      </style>
      <div>
        <div className="blog-img-wrapper">
          <img src={imgSrc} alt="blog-pic" className="blog-img" />
        </div>
      </div>
      <div className="flex-fill p-4 overflow-y-hidden">
        <div className="mh-100 overflow-y-hidden bottom-fade">
          <h6 className="text-secondary-dark">{title}</h6>
          <p className="text-secondary d-none d-md-block overflow-hidden">
            {text}
          </p>
        </div>
      </div>
      <div className="position-absolute b0 r0 text-secondary small pb-2 pr-3">
        Read this post...
      </div>
    </a>
  );
};

const VisitOurBlog = () => {
  const { data: blogPosts, error: blogPostsError } = useSWR<BlogPost[]>(
    'getPosts',
    () => blogService.getPosts()
  );

  const renderCarousel = () => {
    if (blogPosts) {
      return (
        <BlogCarousel>
          {blogPosts.map((blogPost, idx) => (
            <div key={idx} className="py-4">
              <BlogItem
                link={blogPost.link}
                title={blogPost.title.rendered}
                imgSrc={blogPost.jetpack_featured_media_url}
                text={stripHtmlAndGetFirstSentence(blogPost.excerpt.rendered)}
              />
            </div>
          ))}
        </BlogCarousel>
      );
    }

    if (blogPostsError) {
      return <div>Failed to load blog posts</div>;
    }

    return <BlogItemLoader />;
  };

  return (
    <div>
      <div className="mb-5 carousel-wrapper">{renderCarousel()}</div>

      <div className="text-center">
        <Button
          tag="a"
          href={blogService.getBlogURL()}
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          className="shadow-sm rounded-50 px-5 py-1 btn-lg-lg"
        >
          Read All Posts
        </Button>
      </div>
    </div>
  );
};

export default VisitOurBlog;
